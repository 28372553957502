
.section-modal {
	&:focus {
		outline: 0 !important;
	}
	.modal-dialog {
		max-width: 400px;
		input {
			width: 100%;
		}
		.modal-body {
			.el-select {
				color: #000000;
				width: 100%;
				.el-input input.el-input__inner {
					background-color: #ffffff !important;
					border-radius: 4px !important;
					border: 1px solid #c0c4cc;
					width: 100%;
					color: black;
					&::placeholder {
						color: #808080 !important;
						opacity: 1 !important;
					}
				}
			}
		}
		.modal-dialog.modal-sm {
			max-width: 400px;
		}
	}
	.el-input.is-disabled {
		.el-input__inner {
			box-shadow: none !important;
		}
	}
}
