<template>
	<div id="app">
		<div class="warning-banner" v-html="bannerInfo.warningBannerContent" v-if="bannerInfo.warningBannerIsEnabled"></div>
		<div class="wrapper " :class="{ 'nav-open': $sidebar.showSidebar }">
			<notifications></notifications>
			<app-sidebar v-if="!isVisible"></app-sidebar>
			<div class="main-panel" :class="{ 'vw-100': isVisible }">
				<app-navbar v-if="!isVisible"></app-navbar>
				<div class="h-100 min-vh-100 d-flex px-0"
					 :class="!isVisible ? 'container' : ''"
					 :style="{ paddingTop: !isVisible ? '62px' : '0px' }"
					 @click="toggleSidebar()">
					<router-view class="h-100 w-100 overflow-x-hidden"
								 :class="[!isVisible ? 'px-3 py-3' : 'px-0 py-0']" />
				</div>
			</div>
		</div>
		<confirm-delete-modal></confirm-delete-modal>
	</div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import AppNavbar from "@/components/Navbar/Navbar.vue";
import AppSidebar from "@/components/Sidebar/Sidebar.vue";
import { Action, Getter } from "vuex-class";
import { Messageable } from "./interfaces/store/Messageable";
import ACTIONS from "./store/action-definitions";
import cookieHelper from "./helpers/cookieHelper";
import ConfirmDeleteModal from "./components/shared/Modal/ConfirmDeleteModal.vue";

@Component({
	components: {
		AppNavbar,
		AppSidebar,
		ConfirmDeleteModal
	}
})
export default class App extends Vue {
	private pagesWithoutMenu = ["reportEditor", "login"];
    public bannerInfo = { warningBannerContent: "", warningBannerIsEnabled: false };

	@Action(ACTIONS.RESTORE_SESSION) restoreSession: () => void;
	@Action(ACTIONS.SET_LANGUAGE) setLanguage: (locale: string) => void;
	@Action(ACTIONS.SET_LANGUAGES) fetchLanguages: (callback: Function) => void;
	@Getter("getMessage") message!: Messageable;
	@Getter("isLoggedIn") isLoggedIn!: boolean;

	@Watch("message")
	onMessageChanged(newVal: Messageable) {
		if (newVal.message.length > 0)
			this.$notify({
				message: newVal.message,
				icon: "now-ui-icons loader_gear",
				horizontalAlign: "right",
				verticalAlign: "bottom",
				type: newVal.type
			} as any);
	}
	@Watch("isLoggedIn")
	onLoggedOut(newVal: boolean) {
		if (newVal === null) this.$router.push({ name: "login" });
	}

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;
	async created() {
		await this.fetchLanguages(() =>
			this.$languages.getSupportedLanguages()
		);
		if (!cookieHelper.hasLanguageCookie()) {
			this.setLanguage(cookieHelper.generateLanguageCookie());
		}
		if (cookieHelper.hasSessionCookie()) {
			this.restoreSession();
		}
	}

	get isVisible(): boolean {
		const result =this.pagesWithoutMenu.includes(this.$route.name ?? "");
		document.body.style.overflow = result ? "hidden" : "auto"
		return result;
	}

	public toggleSidebar(): void {
		if (this.$sidebar && this.$sidebar.showSidebar === true) {
			this.$sidebar.displaySidebar(false);
		}
	}

    mounted() {
        this.GetWarningBannerInfo();
    }

    async GetWarningBannerInfo(): Promise<void> {
        const { data, success } = await this.$settings.getWarningBannerInfo(this.$store.getters.getShortLocale);
        if (success) {
            this.bannerInfo.warningBannerIsEnabled = data.isEnabled;
            this.bannerInfo.warningBannerContent = data.content;
        }
    }
}
</script>

<style>
	.warning-banner {
		height: 100%;
		width: 100%;
		text-align: center;
		background-color: rgb(240, 192, 30);
	}
</style>
